import { CalcOption, ExchangeData, Totals } from "./budget";

export type ServiceData = {
  _id?: string;
  type: string;
  calcOption: CalcOption;
  supplierId: string;
  supplierName: string;
  budgetValues: ServiceValues;
  negotiationValue: ServiceValues[];
  closingValues: ServiceValues;
};

export type Descriptions = {
  composition: string;
  cancelPolicy: string;
  paymentMethod: string;
};
export type ServiceValues = {
  serviceType: string;
  inputDate: string;
  outDate: string;
  daily: number;
  quantity: number;
  serviceApplication: string;
  observation: string;
  taxData: TaxData;
  totals: Totals;
  exchangeData: ExchangeData;
  descriptions: Descriptions;
};

export type TaxData = {
  tariff: DefaultTax;
  serviceTax: DefaultTax;
  localTax: DefaultTax;
  tourismTax: DefaultTax;
  iof: DefaultTax;
  fee: DefaultTax;
  iss: DefaultTax;
  typeNfMm: CalcOption;
  overhead: DefaultTax;
  markup: DefaultTax;
  commission: DefaultTax;
  incentive: DefaultTax;
  tribute: DefaultTax;
};
export type DefaultTax = {
  typeTax: string;
  value: number;
  currencyValue: number;
  valueConverted: number;
  iss?: number;
  issTariffTaxCalculated?: number;
  fullCoinService?: number;
  totalServiceConverted?: number;
};

export enum typeValueOptions {
  FIX_VALUE = "Valor",
  PERCENTAGE = "%",
}
export enum cortesyOptions {
  CORTESY = "Cortesia",
  FIX_VALUE = "Valor Fixo",
  DAILY = "Diária",
}
export enum NFMMOptions {
  A = "A - Cobrar automaticamente apenas FEE",
  B = "B - Cobrar automaticamente FEE + Imposto apenas sobre FEE",
  C = "C - Cobrar automaticamente FEE + Imposto sobre Tudo (inclusive imposto ISS)",
  D = "D - Cobrar automaticamente APENAS Imposto sobre Serviço (não cobrar FEE)",
  E = "E - Não cobrar nada",
}
export enum typeTax {
  TARIFF = "Tarifa",
  SERVICE = "Taxa de Serviço",
  ISS = "ISS",
  TRIBUTE = "Imposto",
  LOCAL_TAX = "Imposto Local",
  TOURISM_TAX = "Taxa de Turismo",
  FEE = "Taxa MM (Fee)",
  IOF = "IOF",
  MARKUP = "Markup",
  OVER = "Over",
  COMMISSION = "Comissão",
  INCENTIVE = "Incentivo",
}

export function mapTypeTaxToTaxKey(type: typeTax): TaxKey | undefined {
  const typeTaxMap: Record<typeTax, TaxKey> = {
    [typeTax.TARIFF]: "tariff",
    [typeTax.SERVICE]: "serviceTax",
    [typeTax.LOCAL_TAX]: "localTax",
    [typeTax.TOURISM_TAX]: "tourismTax",
    [typeTax.FEE]: "fee",
    [typeTax.IOF]: "iof",
    [typeTax.ISS]: "tariff",
    [typeTax.MARKUP]: "markup",
    [typeTax.OVER]: "overhead",
    [typeTax.INCENTIVE]: "incentive",
    [typeTax.COMMISSION]: "commission",
    [typeTax.TRIBUTE]: "tribute",
  };

  return typeTaxMap[type];
}

export enum typeTaxKeys {
  TARIFF = "tariff",
  SERVICE = "serviceTax",
  ISS = "iss",
  LOCAL_TAX = "localTax",
  TOURISM_TAX = "tourismTax",
  FEE = "fee",
  IOF = "iof",
}
export type typeTaxKey =
  | "tariff"
  | "serviceTax"
  | "iss"
  | "localTax"
  | "tourismTax"
  | "fee"
  | "iof";

export type TaxKey = keyof TaxData;

export const serviceValues = {
  serviceType: "",
  inputDate: "", // Date should be set
  outDate: "", // Date should be set
  daily: 1,
  quantity: 1,
  serviceApplication: "", // Description or application details
  observation: "",
  taxData: {
    tariff: {
      typeTax: "DAILY",
      value: 0,
      currencyValue: 0,
      valueConverted: 0,
    },
    serviceTax: {
      typeTax: "FIX_VALUE",
      value: 0,
      currencyValue: 0,
      valueConverted: 0,
    },
    localTax: {
      typeTax: "FIX_VALUE",
      value: 0,
      currencyValue: 0,
      valueConverted: 0,
    },
    tourismTax: {
      typeTax: "FIX_VALUE",
      value: 0,
      currencyValue: 0,
      valueConverted: 0,
    },
    iof: {
      typeTax: "FIX_VALUE",
      value: 0,
      currencyValue: 0,
      valueConverted: 0,
    },
    fee: {
      typeTax: "FIX_VALUE",
      value: 0,
      currencyValue: 0,
      valueConverted: 0,
    },
    typeNfMm: "A" as CalcOption,
    overhead: {
      typeTax: "FIX_VALUE",
      value: 0,
      currencyValue: 0,
      valueConverted: 0,
      iss: undefined,
      issTariffTaxCalculated: undefined,
      fullCoinService: undefined,
      totalServiceConverted: undefined,
    },
    markup: {
      typeTax: "FIX_VALUE",
      value: 0,
      currencyValue: 0,
      valueConverted: 0,
      iss: undefined,
      issTariffTaxCalculated: undefined,
      fullCoinService: undefined,
      totalServiceConverted: undefined,
    },
    commission: {
      typeTax: "FIX_VALUE",
      value: 0,
      currencyValue: 0,
      valueConverted: 0,
      iss: undefined,
      issTariffTaxCalculated: undefined,
      fullCoinService: undefined,
      totalServiceConverted: undefined,
    },
    incentive: {
      typeTax: "FIX_VALUE",
      value: 0,
      currencyValue: 0,
      valueConverted: 0,
      iss: undefined,
      issTariffTaxCalculated: undefined,
      fullCoinService: undefined,
      totalServiceConverted: undefined,
    },
    iss: {
      typeTax: "PERCENTAGE",
      value: 0,
      currencyValue: 0,
      valueConverted: 0,
      iss: undefined,
      issTariffTaxCalculated: undefined,
      fullCoinService: undefined,
      totalServiceConverted: undefined,
    },
    tribute: {
      typeTax: "FIX_VALUE",
      value: 0,
      currencyValue: 0,
      valueConverted: 0,
      iss: undefined,
      issTariffTaxCalculated: undefined,
      fullCoinService: undefined,
      totalServiceConverted: undefined,
    },
  },
  totals: {
    totalInBRL: 0,
    totalInCurrency: 0,
  },
  exchangeData: {
    date: "",
    currency: "BRL",
    value: 1,
  },
  descriptions: {
    composition: "",
    cancelPolicy: "",
    paymentMethod: "",
  },
};

export const initialServiceData: ServiceData = {
  _id: "", // Optional, initially empty
  type: "", // Should be set according to the actual service type
  supplierId: "", // Supplier ID, initially empty
  supplierName: "", // Supplier ID, initially empty
  budgetValues: serviceValues,
  negotiationValue: [serviceValues],
  closingValues: serviceValues,
  calcOption: "A",
};
