import {
  Alert,
  Box,
  Card,
  Chip,
  Snackbar,
  Slide,
  Tab,
  Tabs,
  Typography,
  Button,
} from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import AerialStep from "../../components/Aereo";
import { AlertModal } from "../../components/AlertModal";
import Criativo from "../../components/Criativo/Criativo";
import Producao from "../../components/Criativo/Producao";
import Logistica from "../../components/Logistics";
import AuditModal from "../../components/novos/Audit/Modal/AuditModal";
import BudgetStep from "../../components/novos/Budget";
import RSVPStep from "../../components/novos/RSVP";
import ProjectStep from "../../components/Project";
import ProjectStatusModal from "../../components/Project/templates/ProjectStatusModal";
import SummaryStep from "../../components/Summary";
import { useProject } from "../../contexts/project.context";
import { BRIEFING_STATUS_OPTIONS } from "../../utils/briefingStatus";
import BoothStep from "../../components/novos/Booth";
import { useBudget } from "../../contexts/BudgetContext/projectbudgets.context";
import { UnsavedChangesModal } from "../../components/Summary/components/UnsavedChangesModal";
import { toast } from "react-toastify";

const StyledSpan = styled.span`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`;

const Project = () => {
  const {
    loadProject,
    project,
    clearProject,
    notification,
    closeNotification,
    tabActive,
    setTabActive,
    unsavedSummary,
    handleSetUnsavedSummary,
  } = useProject();
  const {
    loadBudgetList,
    hasNegotiations,
    hasClosings,
    budgetList,
    setNegotiationVersion,
    setDetailedBudget,
    activeBudgetDispatch,
  } = useBudget();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get("projectId");
  const [showUpdateStatus, setShowUpdateStatus] = useState(false);

  const tabs = [
    {
      index: 0,
      label: "Dados Cadastrais",
      template: ProjectStep,
      area: "project",
      disabled: false,
    },
    {
      index: 1,
      label: "Resumo",
      template: SummaryStep,
      area: "",
      disabled: false,
    },
    {
      index: 2,
      label: "Logística",
      template: Logistica,
      area: "logistics",
      disabled: false,
    },
    {
      index: 3,
      label: "Aéreo",
      template: AerialStep,
      area: "",
      disabled: false,
    },
    { index: 4, label: "RSVP", template: RSVPStep, area: "", disabled: false },
    {
      index: 5,
      label: "Criativo",
      template: Criativo,
      area: "",
      disabled: false,
    },
    {
      index: 6,
      label: "Produção",
      template: Producao,
      area: "",
      disabled: false,
    },
    {
      index: 7,
      label: "Estande",
      template: BoothStep,
      area: "",
      disabled: false,
    },
    // {
    //   index: 8,
    //   label: "Orçamento",
    //   template: BudgetStep,
    //   area: "budget",
    //   disabled: false,
    // },
    // {
    //   index: 9,
    //   label: "Negociação",
    //   template: BudgetStep,
    //   area: "budget",
    //   disabled: !hasNegotiations,
    // },
    // {
    //   index: 10,
    //   label: "Fechamento",
    //   template: BudgetStep,
    //   area: "budget",
    //   disabled: !hasClosings,
    // },
  ];
  const [pendingTab, setPendingTab] = useState(-1);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showAuditModal, setShowAuditModal] = useState(false);
  const [unsavedSummaryModal, setUnsavedSummaryModal] = useState(false);
  const Template = tabs[tabActive].template;

  useEffect(() => {
    if (pendingTab >= 0) {
      if (pendingTab === 9) {
        const newActiveBudget = budgetList?.find(
          (el) => !!el.versionHistory.find((el) => el.status === "NEGOTIATE")
        );
        const firstNegVersion = newActiveBudget?.versionHistory.find(
          (el) => el.status === "NEGOTIATE"
        );

        if (!!newActiveBudget) {
          setNegotiationVersion(firstNegVersion?.version || 0);
          setDetailedBudget(
            `${newActiveBudget._id}-version-${firstNegVersion?.version || 1}`
          );
          console.log("type: NEW_ACTIVE_BUDGET");

          activeBudgetDispatch({
            type: "NEW_ACTIVE_BUDGET",
            payload: newActiveBudget,
          });
        }
      }
      if (pendingTab === 8) {
        const newActiveBudget = budgetList?.find(
          (el) => el.versionHistory[0].status === "OPEN"
        );

        if (newActiveBudget) {
          setNegotiationVersion(0);
          setDetailedBudget(newActiveBudget?._id || "");

          activeBudgetDispatch({
            type: "NEW_ACTIVE_BUDGET",
            payload: newActiveBudget,
          });
        }
      }
      if (pendingTab === 10) {
        const newActiveBudget = budgetList?.find(
          (el) => !!el.versionHistory.find((el) => el.status === "APROVED")
        );
        const firstApprVersion = newActiveBudget?.versionHistory.find(
          (el) => el.status === "APROVED"
        );

        if (!!newActiveBudget) {
          const detailedBudgetId = firstApprVersion
            ? `${newActiveBudget._id}-version-${firstApprVersion.version}`
            : `${newActiveBudget._id}-version-1`;

          setNegotiationVersion(firstApprVersion?.version || 0);
          setDetailedBudget(detailedBudgetId);

          activeBudgetDispatch({
            type: "NEW_ACTIVE_BUDGET",
            payload: newActiveBudget,
          });
        }
      }

      if (localStorage.getItem("hideAlert") !== "0") {
        unsavedSummary?.active
          ? setUnsavedSummaryModal(true)
          : setShowAlertModal(true);
      } else {
        setTabActive(pendingTab);
        setPendingTab(-1);
      }
    }
  }, [pendingTab]);

  useEffect(() => {
    handleSetUnsavedSummary({ active: false, saveFn: null });
  }, []);

  function changeTab(status: boolean) {
    if (status && !!projectId) setTabActive(pendingTab);
    setShowAlertModal(false);
    setUnsavedSummaryModal(false);
    status && handleSetUnsavedSummary({ active: false, saveFn: null });
    setPendingTab(-1);
  }

  async function handleDecideUnsavedSummary(status: boolean) {
    if (!status) {
      changeTab(true);
      return;
    }

    const response = await unsavedSummary?.saveFn();
    changeTab(true);
  }

  function handleChangeTab(index: any) {
    if (projectId) {
      if (index === 9 && !hasNegotiations) {
        return toast.warn(
          "Esse Projeto ainda não possui nenhuma negociação ativa"
        );
      }
      if (index === 10 && !hasClosings) {
        return toast.warn(
          "Esse Projeto ainda não possui nenhum fechamento ativo"
        );
      }
      setPendingTab(index);
    }
  }
  useEffect(() => {
    if (projectId) {
      loadProject(projectId);
      loadBudgetList(projectId);
    }

    return clearProject();
  }, []);

  const pageName = project
    ? `Editar projeto MM ${project.mmCode}.${project.yearCode}`
    : "Criar projeto";
  console.log(hasNegotiations, hasClosings);
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      py={4}
      px={3}
      bgcolor={"#F9FCFF"}
      flex={1}
    >
      {notification.open && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={6000}
          open={notification.open}
          onClose={closeNotification}
          TransitionComponent={(props) => <Slide {...props} direction="left" />}
        >
          <Alert
            onClose={closeNotification}
            severity={notification.severity}
            sx={{ width: "100%" }}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      )}

      {showUpdateStatus && (
        <ProjectStatusModal
          isOpen={showUpdateStatus}
          onClose={() => setShowUpdateStatus(false)}
          status={project?.status}
        />
      )}
      {showAuditModal && (
        <AuditModal
          isOpen={showAuditModal}
          onClose={() => setShowAuditModal(false)}
          area={tabs[tabActive].area}
          label={tabs[tabActive].label}
        />
      )}

      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box gap={1}>
          <Box display={"flex"} gap={1}>
            <Typography variant={"h5"} color={"#A1A1A1"}>
              Projetos/
            </Typography>
            <Typography variant={"h5"}>{pageName}</Typography>
          </Box>

          <Typography variant={"caption"}>
            Adicione as informações de um projeto{" "}
            {projectId &&
              project &&
              ` - última edição ${format(
                new Date(project.updatedAt),
                "dd/MM - HH:mm"
              )} por ${project.lastUserUpdated?.name ?? ""}`}
          </Typography>
        </Box>

        {projectId && project?.status && (
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            <Chip
              label={
                BRIEFING_STATUS_OPTIONS.find(
                  (item) => item.value === project.status.status
                )?.text
              }
              onClick={() => setShowUpdateStatus(true)}
              onDelete={() => setShowUpdateStatus(true)}
              deleteIcon={<MdEdit />}
            />

            {tabs[tabActive].area !== "" && (
              <Button
                variant="outlined"
                color="info"
                onClick={() => setShowAuditModal(true)}
              >
                Auditoria
              </Button>
            )}
          </Box>
        )}
      </Box>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 4,
          boxShadow: "0px 4px 18px 0px #4B465C1A",
          width: "100%",
        }}
      >
        <AlertModal
          handleClose={() => changeTab(false)}
          isOpen={showAlertModal}
          makeDecision={changeTab}
        />

        <UnsavedChangesModal
          handleClose={() => changeTab(false)}
          isOpen={unsavedSummaryModal}
          makeDecision={handleDecideUnsavedSummary}
        />

        <Tabs
          value={tabActive}
          onChange={(e, index) => handleChangeTab(index)}
          indicatorColor="primary"
          sx={{ textTransform: "none" }}
        >
          {tabs.map((tab) => (
            <Tab
              disabled={tab.disabled}
              key={tab.index}
              label={tab.label}
              sx={{
                textTransform: "none",
                background: tab.disabled ? "#e7e7e7e7" : "",
                "&:disabled": {
                  background: "red",
                },
              }}
            />
          ))}
        </Tabs>
        <Box
          overflow={"auto"}
          sx={{
            backgroundColor: "#F9FCFF",
          }}
        >
          <Template />
          {projectId && (
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-end"}
              px={10}
              py={2}
              gap={2}
            >
              {tabActive > 0 && (
                <StyledSpan onClick={() => handleChangeTab(tabActive - 1)}>
                  <FaCaretLeft />
                  Voltar
                </StyledSpan>
              )}
              {tabActive < tabs.length - 1 && (
                <StyledSpan onClick={() => handleChangeTab(tabActive + 1)}>
                  Avançar
                  <FaCaretRight />
                </StyledSpan>
              )}
            </Box>
          )}
        </Box>
      </Card>
    </Box>
  );
};

export default Project;
