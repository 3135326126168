import { useContext, useEffect, useState } from "react";
import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
  Redirect,
} from "react-router-dom";

import SideBar from "../components/SideBar2";
import { AuthContext } from "../contexts/auth";

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  hasSideBar?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  hasSideBar = true,
  component: Component,
  ...rest
}) => {
  const { user, accessOptions } = useContext(AuthContext);
  const [triggerRedirect, setTriggerRedirect] = useState(false);

  const hasDashboardPermission = accessOptions.find(
    (option) => option.name === "Dashboard"
  )?.type?.view?.active;

  useEffect(() => {
    if (accessOptions?.length || !user) {
      setTriggerRedirect(true);
    }
  }, [accessOptions, user]);

  useEffect(() => {
    if (triggerRedirect) setTriggerRedirect(false);
  }, [triggerRedirect]);

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate === !!user ? (
          <div
            style={{ display: "flex", flexDirection: "row", height: "100vh" }}
          >
            {isPrivate && hasSideBar && <SideBar />}
            <Component />
          </div>
        ) : triggerRedirect ? (
          <Redirect
            to={{
              pathname: isPrivate
                ? "/"
                : hasDashboardPermission
                ? "/projetos"
                : "/projetos",
              state: { from: location },
            }}
          />
        ) : null;
      }}
    />
  );
};

export { Route };
