import { Page, View, Text, Image } from "@react-pdf/renderer";
import { ptBR } from "date-fns/locale";
import { format } from "date-fns";
import { styles } from "./styles";
import { BudgetItemTypeEnum } from "../../types/budget/budget";
import { ServiceOptions } from "../../utils/serviceTypesFromBudget";

const groupByAttributes = (array: any[], keys: any) => {
  const groups: any = {};

  array.forEach((item) => {
    const key = keys.map((k: any) => item[k]).join("|");

    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push(item);
  });

  return groups;
};

const CalcOptionLabels: { [key: string]: any } = {
  A: "[A] CUSTOS DE TERCEIROS",
  B: "[B] NOTA DEBITO",
  C: "[C] CUSTOS DE TERCEIROS (FATURADOS CONTRA A MM) - BITRIBUTADO",
  D: "[D] CUSTOS INTERNOS",
  E: "E",
};

export function ExportDescriptive(props: any) {
  const budget = props?.budgetData;
  const client = props?.clientData;
  const isClosing = props?.isClosing;

  const budgets = budget.map((item: any, index: number) => {
    const services = item?.budgetServices;
    const budgetIndex = item?.budgetIndex;
    const budgetName = item?.name;

    const groupedServices = groupByAttributes(services, [
      "type",
      "calcOption",
      "supplierId",
    ]);

    const mappedServices = Object.entries(groupedServices).map(
      ([key, value]) => {
        return {
          key,
          items: value,
        };
      }
    );

    const formattedServices: any[] = mappedServices.map((m: any) => {
      const [type, calc, supplier] = m.key.split("|");

      const formattedItems = m.items.map((i: any, itemIndex: number) => {
        const values = isClosing ? i?.closingValues : i?.budgetValues;

        const items = ServiceOptions[type];
        const itemName = items.find((i: any) => i.value === values.serviceType);

        return {
          product: itemName?.text || "-",
          descriptions: values?.descriptions,
        };
      });

      const typeTag: keyof typeof BudgetItemTypeEnum = type;
      const typeName = BudgetItemTypeEnum[typeTag];

      const descriptive = services?.find(
        (e: any) =>
          e.type === type &&
          e?.calcOption === calc &&
          e?.supplierId === supplier
      );

      return {
        type: typeName,
        items: formattedItems,
        calcOption: calc,
        supplier,
        descriptions: descriptive?.budgetValues?.descriptions,
      };
    });

    const formattedGroupedServices = groupByAttributes(formattedServices, [
      "calcOption",
    ]);

    return {
      name: `${budgetName}`,
      services: formattedGroupedServices,
    };
  });

  const mockedColumns = [
    {
      name: "Produto",
      total: "Total",
      flex: 1,
    },
    {
      name: "Composição",
      flex: 1,
      total: " ",
      size: 3,
    },
    {
      name: "Forma de pagamento",
      total: " ",
      flex: 1,
    },
    {
      name: "Politica de cancelamento",
      total: " ",
      flex: 1,
    },
  ];

  function addLineBreaks(input: string): string {
    const parts: string[] = [];

    for (let i = 0; i < input.length; i += 8) {
      const part = input.substring(i, i + 8);
      parts.push(part);
    }

    return parts.join("\n");
  }

  function renderHeader() {
    return (
      <>
        {props?.showClientHeader && (
          <View style={styles.exportHeader}>
            <div style={{ ...styles.headerDiv, ...styles.backgroundBlack }}>
              <div style={styles.childHeaderDiv}>
                <Text
                  style={{
                    ...styles.whiteText,
                    fontSize: 22,
                    fontWeight: "bold",
                  }}
                >
                  {client?.name}
                </Text>
                <br />
                <Text
                  style={{
                    ...styles.grayText,
                    fontSize: 22,
                    fontWeight: "bold",
                  }}
                >
                  {client?.nickname}
                </Text>
              </div>
            </div>
            <div style={{ ...styles.headerDiv, ...styles.backgroundGray }}>
              <div
                style={{
                  ...styles.childHeaderDiv,
                  ...styles.textSpan,
                  ...styles.grayText,
                }}
              >
                <div style={{ padding: "8px 0" }}>
                  <Text style={{ color: "#727272", fontSize: 14 }}>Evento</Text>
                </div>
                <Text style={{ color: "#272727" }}>{client.event.name}</Text>
                <div style={styles.textDiv}>
                  <Text>Inicio: </Text>
                  <Text style={{ color: "#272727" }}>
                    {format(new Date(client.event.startDate), "dd/MM/yyyy")}
                  </Text>
                </div>
                <div style={styles.textDiv}>
                  <Text>Término: </Text>
                  <Text style={{ color: "#272727" }}>
                    {format(new Date(client.event.endDate), "dd/MM/yyyy")}
                  </Text>
                </div>
                <div style={styles.textDiv}>
                  <div style={styles.textDiv}>
                    <Text>PFZ: </Text>
                    <Text>{client.event.pfzCode}</Text>
                  </div>
                  <div style={styles.textDiv}>
                    <Text> MAAP: </Text>
                    <Text>{client.event.mappCode}</Text>
                  </div>
                </div>
              </div>
              <div style={styles.childHeaderDiv2}>
                <div
                  style={{
                    ...styles.textDiv,
                    padding: "10px 0",
                    alignItems: "flex-end",
                  }}
                >
                  <Text
                    style={{
                      ...styles.textSpan,
                      ...styles.grayText,
                    }}
                  >
                    Nº MM{" "}
                  </Text>
                  <Text style={{ ...styles.textSpan, ...styles.grayText }}>
                    {client.mmCode}
                  </Text>
                </div>

                <br />
                <Text>Atendimento</Text>
                <div style={styles.textDiv}>
                  <Text style={{ ...styles.textSpan }}>Solicitante </Text>
                  <Text style={{ ...styles.textSpan, ...styles.grayText }}>
                    {client.requester}
                  </Text>
                </div>
                <div style={styles.textDiv}>
                  <Text style={{ ...styles.textSpan }}>Atend/Comercial </Text>
                  <Text style={{ ...styles.textSpan, ...styles.grayText }}>
                    {client.firstAttendance}
                  </Text>
                </div>
                <div style={styles.textDiv}>
                  <Text style={{ ...styles.textSpan }}>Coordenador </Text>
                  <Text style={{ ...styles.textSpan, ...styles.grayText }}>
                    {client.commercial}
                  </Text>
                </div>
              </div>
            </div>
          </View>
        )}
        <View style={styles.view}>
          <div style={{ width: "98%" }}>
            <Text style={{ ...styles.header2, fontWeight: "bold" }}>
              Descritivos
            </Text>
            <Text
              style={{
                ...styles.header2,
                fontSize: "14px",
                ...styles.grayText,
                marginTop: "10px",
              }}
            >
              Comparações de orçamentos
            </Text>
          </div>
        </View>
      </>
    );
  }
  return (
    <Page size="A3" orientation="landscape" style={styles.page}>
      {props.showHeader && renderHeader()}
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          width: "98%",
          marginLeft: 12,
          marginRight: 12,
          marginTop: 8,
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          {budgets.map((budget: any) => {
            return (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "black",
                    alignItems: "center",
                  }}
                >
                  <Text style={{ color: "white", margin: 8, fontSize: 10 }}>
                    {budget?.name}
                  </Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 4,
                      margin: 8,
                    }}
                  >
                    <Text style={{ color: "white" }}>
                      {client?.event?.local}
                    </Text>
                    <Text
                      style={{
                        fontSize: 10,
                        maxWidth: 200,
                        color: "#C1C1C1",
                      }}
                    >
                      {client.event.neighborhood} {client.event.number},{" "}
                      {client.event.city}, {client.event.state},{" "}
                      {client.event.country}
                      {"\n"}
                      {format(new Date(client.event.startDate), "d MMMM yyyy", {
                        locale: ptBR,
                      })}{" "}
                      à{" "}
                      {format(new Date(client.event.endDate), "d MMMM yyyy", {
                        locale: ptBR,
                      })}
                    </Text>
                  </div>
                </div>
                {Object.entries(budget?.services).map(([key, value]: any) => {
                  return (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          backgroundColor: "#505050",
                          padding: "12px 4px",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 10,
                            color: "#FFFFFF",
                            marginLeft: 25,
                          }}
                        >
                          {CalcOptionLabels?.[key]}
                        </Text>
                      </div>
                      <View>
                        {value.map((v: any) => {
                          return (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: "100%",
                                  backgroundColor: "#B3B3B3",
                                  padding: 4,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 10,
                                    color: "#373737",
                                    marginLeft: 25,
                                  }}
                                >
                                  {v?.type}
                                </Text>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  borderBottom: "1px solid #B3B3B3",
                                }}
                              >
                                {mockedColumns.map((item, index) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      flex: item.flex,
                                      padding: 4,
                                      backgroundColor:
                                        index === 0 ? "#F3F3F3" : "white",
                                      borderRight:
                                        index === 0
                                          ? "1px solid #B3B3B3"
                                          : "1px solid white",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        color: "#7C7C7C",
                                        fontSize: 10,
                                      }}
                                    >
                                      {item.name}
                                    </Text>
                                  </div>
                                ))}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    borderRight: "1px solid #B3B3B3",
                                    backgroundColor: "#F3F3F3",
                                    padding: 4,
                                  }}
                                >
                                  {v?.items?.map((i: any, index: number) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        padding: "16px 0px",
                                        borderBottom:
                                          index === v?.items?.length - 1
                                            ? "none"
                                            : "1px solid #B3B3B3",
                                      }}
                                    >
                                      {" "}
                                      <Text
                                        style={{
                                          fontSize: 10,
                                          color: "#373737",
                                        }}
                                      >
                                        {i?.product}
                                      </Text>
                                    </div>
                                  ))}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    borderRight: "1px solid #B3B3B3",
                                    backgroundColor: "#F3F3F3",
                                    padding: 4,
                                  }}
                                >
                                  {v?.items?.map((i: any, index: number) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        padding: "16px 0px",
                                        borderBottom:
                                          index === v?.items?.length - 1
                                            ? "none"
                                            : "1px solid #B3B3B3",
                                      }}
                                    >
                                      {" "}
                                      <Text
                                        style={{
                                          fontSize: 10,
                                          color: "#373737",
                                        }}
                                      >
                                        {i?.descriptions?.composition || "-"}
                                      </Text>
                                    </div>
                                  ))}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    borderRight: "1px solid #B3B3B3",
                                    backgroundColor: "#F3F3F3",
                                    padding: 4,
                                  }}
                                >
                                  {v?.items?.map((i: any, index: number) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        padding: "16px 0px",
                                        borderBottom:
                                          index === v?.items?.length - 1
                                            ? "none"
                                            : "1px solid #B3B3B3",
                                      }}
                                    >
                                      {" "}
                                      <Text
                                        style={{
                                          fontSize: 10,
                                          color: "#373737",
                                        }}
                                      >
                                        {i?.descriptions?.paymentMethod || "-"}
                                      </Text>
                                    </div>
                                  ))}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    borderRight: "1px solid #B3B3B3",
                                    backgroundColor: "#F3F3F3",
                                    padding: 4,
                                  }}
                                >
                                  {v?.items?.map((i: any, index: number) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        padding: "16px 0px",
                                        borderBottom:
                                          index === v?.items?.length - 1
                                            ? "none"
                                            : "1px solid #B3B3B3",
                                      }}
                                    >
                                      {" "}
                                      <Text
                                        style={{
                                          fontSize: 10,
                                          color: "#373737",
                                        }}
                                      >
                                        {i?.descriptions?.cancelPolicy || "-"}
                                      </Text>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </View>
                    </div>
                  );
                })}
              </>
            );
          })}
        </div>
      </View>
    </Page>
  );
}
