import { api } from "./apiClient";

export default class AerialService {
  static async get(projectId: string): Promise<any> {
    return api.get(`/aerial/${projectId}`).then((response) => response.data);
  }
  static async create(data: any): Promise<any> {
    return api.post("/aerial/create", data).then((response) => response.data);
  }
  static async update(id: string, data: any): Promise<any> {
    return api.put(`/aerial/${id}`, data).then((response) => response.data);
  }
  // static async delete(id: string): Promise<any> {
  //   return api.delete(`/cell/delete/${id}`).then((response) => response.data)
  // }
  static async search(params: any): Promise<any> {
    return api
      .get("/airport/get-by-search", { params })
      .then((response) => response.data);
  }

  static async getAirport(id: string): Promise<any> {
    return await api.get(`/airport/get/${id}`);
  }
}
