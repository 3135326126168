import { Box, FormControlLabel, FormGroup } from "@mui/material";
import { Switch } from "../../UI/Switch";
import { t } from "i18next";
import { ReactNode } from "react";
import YesOrNoSwitch from "../../UI/YesOrNoSwitch";

type PropTypes = {
  icon: ReactNode;
  title: string;
  typeKey: string;
  isActive: boolean;
  isDisabled: boolean;
  changeIsActive(type: string, value: boolean): void;
};

const LogisticsTypesTabItem: React.FC<PropTypes> = ({
  changeIsActive,
  icon,
  isActive,
  title,
  typeKey,
  isDisabled,
}: PropTypes) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          minWidth: "180px",
        }}
      >
        <div
          style={{
            padding: "6px",
            marginRight: "8px",
            borderRadius: "5px",
            background: isActive ? "#1361A4" : "#A5A3AE4D",
            display: "flex",
            alignItems: "center",
            color: isActive ? "white" : "#222222",
          }}
        >
          {icon}
        </div>
        <span>{title}</span>
      </div>
      <YesOrNoSwitch
        setValue={(_, value) => {
          if (value !== null) changeIsActive(typeKey, value);
        }}
        value={isActive}
        type=""
        align="flex-start"
        disabled={isDisabled}
      />
    </Box>
  );
};

export default LogisticsTypesTabItem;
