import React from "react";
import { Page, View, Text, Image } from "@react-pdf/renderer";
import { add, format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { styles } from "./styles";
import { ServiceOptions } from "../../utils/serviceTypesFromBudget";
import { BudgetItemTypeEnum } from "../../types/budget/budget";

const formatter = new Intl.NumberFormat("pt-BR", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const CalcOptionLabels: { [key: string]: any } = {
  A: "[A] CUSTOS DE TERCEIROS",
  B: "[B] NOTA DEBITO",
  C: "[C] CUSTOS DE TERCEIROS (FATURADOS CONTRA A MM) - BITRIBUTADO",
  D: "[D] CUSTOS INTERNOS",
  E: "E",
};

export const formatCurrency = (value: number) => {
  if (!value) return "0,00";
  return formatter.format(value);
};

function formatDate(date: string) {
  return !isNaN(new Date(date).getTime())
    ? format(add(new Date(date), { hours: 4 }), "dd/MM/yyyy")
    : "-";
}

export const groupByAttributes = (array: any[], keys: any) => {
  const groups: any = {};

  array.forEach((item) => {
    const key = keys.map((k: any) => item[k]).join("|");

    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push(item);
  });

  return groups;
};

const convertToNumber = (str: any) => {
  return parseFloat(str.replace(/[^\d,-]/g, "").replace(",", "."));
};

const calculateTotals = (data: any) => {
  let totalTariff = 0;
  let totalCostInCurrency = 0;
  let totalCostInBRL = 0;

  data.forEach((item: any) => {
    const row = item.row;

    totalTariff += convertToNumber(row[8]);
    totalCostInCurrency += convertToNumber(row[9]);
    totalCostInBRL += convertToNumber(row[10]);
  });

  return {
    tariffTotal: totalTariff,
    totalCostInCurrency: totalCostInCurrency,
    totalCostInBRL: totalCostInBRL,
  };
};

function getUniqueKeys<T extends { key: string }>(arr: T[][]): string[] {
  return Array.from(
    new Set(arr.flatMap((innerArray) => innerArray.map((item) => item.key)))
  );
}

function getUniqueValues<T>(array: T[]): T[] {
  return Array.from(new Set(array));
}

function divideInPortions<T>(array: T[], tamanho: number = 6): T[][] {
  const resultado: T[][] = [];
  for (let i = 0; i < array.length; i += tamanho) {
    resultado.push(array.slice(i, i + tamanho));
  }
  return resultado;
}

const mockedColumns = [
  {
    name: "#",
    flex: 0.3,
  },
  {
    name: "Produto",
    flex: 1.3,
  },
  // {
  //   name: "Composição",
  //   flex: 1.4,
  // },
  {
    name: "Entrada",
    flex: 1,
  },
  {
    name: "Saída",
    flex: 1,
  },
  {
    name: "Diárias",
    flex: 0.6,
  },
  {
    name: "Qtd",
    separator: true,
    flex: 0.5,
  },
  // {
  //   name: "Tarifa",
  //   flex: 1,
  // },
  // {
  //   name: "Total tarifa",
  //   flex: 1.1,
  // },
  {
    name: "TotalCurrency",
    flex: 1.1,
    color: "#EDEDED",
  },
  {
    name: "Total (R$)",
    flex: 1.1,
    separator: true,
    color: "#EDEDED",
  },
  {
    name: "Taxa de serviço",
    flex: 1,
    color: "#FFFFFF",
    fontSize: 9,
  },
  {
    name: "ISS",
    flex: 1,
    color: "#FFFFFF",
    fontSize: 9,
  },
  {
    name: "Imposto local",
    flex: 1,
    color: "#FFFFFF",
    fontSize: 9,
  },
  {
    name: "Taxa de turismo",
    flex: 1,
    color: "#FFFFFF",
    fontSize: 9,
  },
  {
    name: "Câmbio",
    flex: 1,
    color: "#FFFFFF",
    fontSize: 9,
  },
  // {
  //   name: "IOF",
  //   flex: 1,
  //   color: "#FFFFFF",
  //   fontSize: 9,
  // },
  {
    name: "Taxa MM",
    flex: 1,
    color: "#FFFFFF",
    fontSize: 9,
  },
  {
    name: "NF",
    flex: 0.3,
    color: "#FFFFFF",
    fontSize: 9,
  },
];

function calculateDetailedSums(items: any): {
  taxes: { totalInBRL: number; totalInCurrency: number };
  totals: { totalInBRL: number; totalInCurrency: number };
} {
  return items?.reduce(
    (acc: any, item: any) => {
      acc.taxes.totalInBRL += item.taxes.totalInBRL;
      acc.taxes.totalInCurrency += item.taxes.totalInCurrency;

      acc.totals.totalInBRL += item.totals.totalInBRL;
      acc.totals.totalInCurrency += item.totals.totalInCurrency;

      return acc;
    },
    {
      taxes: { totalInBRL: 0, totalInCurrency: 0 },
      totals: { totalInBRL: 0, totalInCurrency: 0 },
    }
  );
}

export function ExportNegotiation(props: any) {
  const budget = props.budgetData;
  const isNegotiate = props.isNegotiate;
  const versions = props?.versions;

  const budgetWithVersions = budget.map((budget: any) => {
    const versionArr = versions?.find((i: any) => i?.id === budget?._id);
    return {
      budget,
      version: versionArr.version,
    };
  });

  const client = props?.clientData;

  const formatBudget =
    !!budgetWithVersions?.length &&
    budgetWithVersions?.map((versionBudget: any, index: number) => {
      const item = versionBudget?.budget;
      return versionBudget?.version?.map((version: any) => {
        // const services = item?.budgetServices.filter(
        //   (service: any) => service?.calcOption === "A"
        // );
        const services = item?.budgetServices;
        const exchanges = item?.exchangeAndDescriptionByType;
        const budgetIndex = item?.budgetIndex;
        const budgetName = item?.name;

        const groupedServices = groupByAttributes(services, [
          "type",
          "calcOption",
          "supplierId",
        ]);

        const mappedServices = Object.entries(groupedServices).map(
          ([key, value]) => {
            return {
              key,
              items: value,
            };
          }
        );

        const formattedServices: any[] = mappedServices.map((m: any) => {
          const [type, calc, supplier] = m.key.split("|");

          // const exchange = exchanges?.find(
          //   (e: any) =>
          //     e.type === type &&
          //     e?.calcOption === calc &&
          //     e?.supplierId === supplier
          // )?.exchangeData;

          const exchange =
            m?.items?.[0]?.negotiationValue?.[version]?.exchangeData;

          const isBRL = exchange?.currency === "BRL";

          const totals =
            item?.negociationValuesTotal?.[version]?.byCalculationType?.[calc];

          const itemTotal = totals?.[type];

          const currentTotalItems = itemTotal?.filter(
            (i: any) => i.supplierId === supplier
          );

          const typeTotals = calculateDetailedSums(currentTotalItems);

          const filteredHasVersions = m?.items?.filter(
            (i: any) => i?.negotiationValue[version]
          );

          const formattedItems = filteredHasVersions.map(
            (i: any, itemIndex: number) => {
              // change negotiation here
              // const values = i?.budgetValues;
              const values = i?.negotiationValue[version];

              const taxData = {
                ...values.taxData,
                ...values.totals,
              };

              const items = ServiceOptions[type];
              const itemName = items.find(
                (i: any) => i.value === values.serviceType
              );

              const row = [
                itemIndex + 1,
                itemName?.text,
                // values.observation,
                formatDate(values.inputDate),
                formatDate(values.outDate),
                values.daily,
                values.quantity,
                // formatCurrency(
                //   isBRL
                //     ? taxData?.tariff?.value
                //     : taxData?.tariff?.valueConverted
                // ),
                // formatCurrency(
                //   isBRL
                //     ? taxData?.tariff?.value
                //     : taxData?.tariff?.valueConverted
                // ),
                isBRL ? "-" : formatCurrency(taxData?.tariff?.fullCoinService),
                isBRL
                  ? formatCurrency(taxData?.tariff?.totalServiceConverted)
                  : formatCurrency(
                      taxData?.tariff?.fullCoinService * exchange?.value
                    ),
                formatCurrency(
                  isBRL
                    ? taxData?.serviceTax?.value
                    : taxData?.serviceTax?.valueConverted
                ),
                formatCurrency(taxData?.iss?.valueConverted),
                formatCurrency(
                  isBRL
                    ? taxData?.localTax?.value
                    : taxData?.localTax?.valueConverted
                ),
                formatCurrency(
                  isBRL
                    ? taxData?.tourismTax?.value
                    : taxData?.tourismTax?.valueConverted
                ),
                isBRL ? "-" : `${exchange?.currency} ${exchange?.value}`,
                // formatCurrency(
                //   isBRL ? taxData?.iof?.value : taxData?.iof?.valueConverted
                // ),
                formatCurrency(
                  isBRL ? taxData?.fee?.value : taxData?.fee?.valueConverted
                ),
                taxData?.typeNfMm,
              ];

              return {
                row,
              };
            }
          );

          const typeTag: keyof typeof BudgetItemTypeEnum = type;
          const typeName = BudgetItemTypeEnum[typeTag];

          const typeColumns = mockedColumns.map((item) => {
            if (item.name === "TotalCurrency") {
              return {
                ...item,
                name: isBRL ? "-" : `Total (${exchange?.currency})`,
              };
            } else {
              return item;
            }
          });

          const costAndTariffTotals = calculateTotals(formattedItems);

          return {
            type: typeName,
            totals: typeTotals,
            items: formattedItems,
            columns: typeColumns,
            currency: exchange?.currency,
            currencyValue: exchange?.value,
            tariffTotal: formatCurrency(costAndTariffTotals.tariffTotal),
            tariffTotalNumber: costAndTariffTotals.tariffTotal,
            totalCostInCurrency: isBRL
              ? "-"
              : formatCurrency(costAndTariffTotals.totalCostInCurrency),
            totalCostInCurrencyNumber: isBRL
              ? 0
              : costAndTariffTotals.totalCostInCurrency,
            totalCostInBRL: formatCurrency(costAndTariffTotals.totalCostInBRL),
            totalCostInBRLNumber: costAndTariffTotals.totalCostInBRL,
            calcOption: calc,
          };
        });

        const formattedGroupedServices = groupByAttributes(formattedServices, [
          "calcOption",
        ]);

        const sumTotals: any[] = [];

        Object.entries(formattedGroupedServices).forEach(
          ([key, value]: any) => {
            value.forEach((item: any) => sumTotals.push(item?.totals?.totals));
          }
        );

        const globalCurrencyTotals = sumTotals.reduce((sum: any, item: any) => {
          return sum + item?.totalInCurrency;
        }, 0);

        const globalBRLTotals = sumTotals.reduce((sum: any, item: any) => {
          return sum + item?.totalInBRL;
        }, 0);

        return {
          name: `${budgetName}${
            isNegotiate ? ` - Negociação ${version + 1}` : ""
          }`,
          services: formattedServices,
          servicesGrouped: formattedGroupedServices,
          totals: {
            totalInCurrency: globalCurrencyTotals,
            totalInBRL: globalBRLTotals,
          },
          budgetIndex,
          versionIndex: version + 1,
        };
      });
    });

  const budgetTotals = formatBudget.map((b: any, index1: number) => {
    return b?.map((i: any, index2: number) => {
      const groupedServices = groupByAttributes(i?.services, ["type"]);

      if (Object.keys(groupedServices).length !== 0) {
        const formattedServices = Object.entries(groupedServices).map(
          ([key, value]) => {
            const items: any = value;

            const tariffTotal = items?.reduce((sum: any, item: any) => {
              const valor = item?.tariffTotalNumber || 0;
              return sum + valor;
            }, 0);

            const totalTaxes = items?.reduce((sum: any, item: any) => {
              const valor =
                parseFloat(item?.totals?.taxes?.totalInCurrency) || 0;
              return sum + valor;
            }, 0);

            const totalCostInBRL = items?.reduce((sum: any, item: any) => {
              const valor = parseFloat(item?.totalCostInBRLNumber) || 0;
              return sum + valor;
            }, 0);

            const totalCostInCurrency = items?.reduce((sum: any, item: any) => {
              const valor = parseFloat(item?.totalCostInCurrencyNumber) || 0;
              return sum + valor;
            }, 0);

            return {
              key,
              tariffTotal,
              totalTaxes,
              totalCostInBRL,
              totalCostInCurrency,
              currency: items?.find((item: any) => item?.currency)?.currency,
              hasService: !!b?.services?.length,
              budgetIdx: i?.budgetIndex,
              versionIdx: i?.versionIndex,
              name: i?.name,
            };
          }
        );
        return formattedServices;
      } else {
        return [
          {
            budgetIdx: i?.budgetIndex,
            versionIdx: i?.versionIndex,
            name: i?.name,
          },
        ];
      }
    });
  });

  const mergedBudgetTotals = budgetTotals.reduce(
    (merged: any, current: any) => [...merged, ...current],
    []
  );

  // const budgetTotalKeys = getUniqueKeys(budgetTotals);

  const dividedBudgetTotals = divideInPortions(mergedBudgetTotals);

  // const getBudgetTotalValues = (item: any, key: string, label: string) => {
  //   const keyLabels: any = {
  //     ["Total tarifa"]: "tariffTotal",
  //     ["Total Tax./Imp."]: "totalTaxes",
  //     ["Total (USD)"]: "totalCostInCurrency",
  //     ["Total (R$)"]: "totalCostInBRL",
  //   };

  //   const findItem = item?.find((i: any) => i.key === key);

  //   if (findItem) {
  //     return formatCurrency(findItem[keyLabels[label]]);
  //   } else {
  //     return "-";
  //   }
  // };

  function addLineBreaks(input: string): string {
    const parts: string[] = [];

    for (let i = 0; i < input.length; i += 8) {
      const part = input.substring(i, i + 8);
      parts.push(part);
    }

    return parts.join("\n");
  }

  function renderHeader() {
    return (
      <>
        <View style={styles.exportHeader}>
          <div style={{ ...styles.headerDiv, ...styles.backgroundBlack }}>
            <div style={styles.childHeaderDiv}>
              <Text
                style={{
                  ...styles.whiteText,
                  fontSize: 22,
                  fontWeight: "bold",
                }}
              >
                {client?.name}
              </Text>
              <br />
              <Text
                style={{ ...styles.grayText, fontSize: 22, fontWeight: "bold" }}
              >
                {client?.nickname}
              </Text>
            </div>
          </div>
          <div style={{ ...styles.headerDiv, ...styles.backgroundGray }}>
            <div
              style={{
                ...styles.childHeaderDiv,
                ...styles.textSpan,
                ...styles.grayText,
              }}
            >
              <div style={{ padding: "8px 0" }}>
                <Text style={{ color: "#727272", fontSize: 14 }}>Evento</Text>
              </div>
              <Text style={{ color: "#272727" }}>{client.event.name}</Text>
              <div style={styles.textDiv}>
                <Text>Inicio: </Text>
                <Text style={{ color: "#272727" }}>
                  {format(new Date(client.event.startDate), "dd/MM/yyyy")}
                </Text>
              </div>
              <div style={styles.textDiv}>
                <Text>Término: </Text>
                <Text style={{ color: "#272727" }}>
                  {format(new Date(client.event.endDate), "dd/MM/yyyy")}
                </Text>
              </div>
              <div style={styles.textDiv}>
                <div style={styles.textDiv}>
                  <Text>PFZ: </Text>
                  <Text>{client.event.pfzCode}</Text>
                </div>
                <div style={styles.textDiv}>
                  <Text> MAAP: </Text>
                  <Text>{client.event.mappCode}</Text>
                </div>
              </div>
            </div>
            <div style={styles.childHeaderDiv2}>
              <div
                style={{
                  ...styles.textDiv,
                  padding: "10px 0",
                  alignItems: "flex-end",
                }}
              >
                <Text
                  style={{
                    ...styles.textSpan,
                    ...styles.grayText,
                  }}
                >
                  Nº MM{" "}
                </Text>
                <Text style={{ ...styles.textSpan, ...styles.grayText }}>
                  {client.mmCode}
                </Text>
              </div>

              <br />
              <Text>Atendimento</Text>
              <div style={styles.textDiv}>
                <Text style={{ ...styles.textSpan }}>Solicitante </Text>
                <Text style={{ ...styles.textSpan, ...styles.grayText }}>
                  {client.requester}
                </Text>
              </div>
              <div style={styles.textDiv}>
                <Text style={{ ...styles.textSpan }}>Atend/Comercial </Text>
                <Text style={{ ...styles.textSpan, ...styles.grayText }}>
                  {client.firstAttendance}
                </Text>
              </div>
              <div style={styles.textDiv}>
                <Text style={{ ...styles.textSpan }}>Coordenador </Text>
                <Text style={{ ...styles.textSpan, ...styles.grayText }}>
                  {client.commercial}
                </Text>
              </div>
            </div>
          </div>
        </View>
        <View style={styles.view}>
          <div style={{ width: "98%" }}>
            <Text style={{ ...styles.header2, fontWeight: "bold" }}>
              Proposta
            </Text>
            <Text
              style={{
                ...styles.header2,
                fontSize: "14px",
                ...styles.grayText,
                marginTop: "10px",
              }}
            >
              Comparações de {isNegotiate ? "negociações" : "orçamentos"}
            </Text>
            <Text
              style={{
                ...styles.header2,
                fontSize: "14px",
                ...styles.grayText,
                marginTop: "10px",
              }}
            >
              Moeda padrão: BRL (R$)
            </Text>
          </div>
        </View>
      </>
    );
  }

  return (
    <Page size="A3" orientation="landscape" style={styles.page}>
      {props.showHeader && renderHeader()}
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          width: "98%",
          marginLeft: 12,
          marginRight: 12,
          marginTop: 8,
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          {formatBudget?.map((budgetVersion: any) => {
            return budgetVersion?.map((budget: any) => {
              return (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      backgroundColor: "black",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ color: "white", margin: 8, fontSize: 10 }}>
                      {budget?.name}
                    </Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 4,
                        margin: 8,
                      }}
                    >
                      <Text style={{ color: "white" }}>
                        {client?.event?.local}
                      </Text>
                      <Text
                        style={{
                          fontSize: 10,
                          maxWidth: 200,
                          color: "#C1C1C1",
                        }}
                      >
                        {client.event.neighborhood} {client.event.number},{" "}
                        {client.event.city}, {client.event.state},{" "}
                        {client.event.country}
                        {"\n"}
                        {format(
                          new Date(client.event.startDate),
                          "d MMMM yyyy",
                          {
                            locale: ptBR,
                          }
                        )}{" "}
                        à{" "}
                        {format(new Date(client.event.endDate), "d MMMM yyyy", {
                          locale: ptBR,
                        })}
                      </Text>
                    </div>
                  </div>
                  {Object.entries(budget?.servicesGrouped).map(
                    ([key, value]: any) => {
                      return (
                        <div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                              backgroundColor: "#505050",
                              padding: "12px 4px",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 10,
                                color: "#FFFFFF",
                                marginLeft: 25,
                              }}
                            >
                              {CalcOptionLabels?.[key]}
                            </Text>
                          </div>
                          <View wrap={false}>
                            {value.map((v: any) => {
                              return (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      width: "100%",
                                      backgroundColor: "#B3B3B3",
                                      padding: 4,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 10,
                                        color: "#373737",
                                        marginLeft: 25,
                                      }}
                                    >
                                      {v?.type}
                                    </Text>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      backgroundColor: "#F3F3F3",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "100%",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                            }}
                                          >
                                            {v?.columns?.map((column: any) => (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  borderBottom:
                                                    "1px solid #7C7C7C",
                                                  flex: column.flex,
                                                  alignItems: "center",
                                                  borderRight: column.separator
                                                    ? "1px solid #7C7C7C"
                                                    : "none",
                                                  backgroundColor:
                                                    column.color || "#F3F3F3",
                                                }}
                                              >
                                                <Text
                                                  style={{
                                                    display: "flex",
                                                    color: "#7C7C7C",
                                                    fontSize:
                                                      column.fontSize || 10,
                                                    padding: 4,
                                                  }}
                                                >
                                                  {column.name}
                                                </Text>
                                              </div>
                                            ))}
                                          </div>

                                          {v?.items?.map((item: any) => (
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                              }}
                                            >
                                              {item?.row.map(
                                                (value: any, index: number) => (
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "row",
                                                      borderBottom:
                                                        "1px solid #7C7C7C",
                                                      flex: mockedColumns[index]
                                                        .flex,
                                                      alignItems: "center",
                                                      borderRight:
                                                        mockedColumns[index]
                                                          .separator
                                                          ? "1px solid #7C7C7C"
                                                          : "none",
                                                      backgroundColor:
                                                        mockedColumns[index]
                                                          .color || "#F3F3F3",
                                                    }}
                                                  >
                                                    <Text
                                                      style={{
                                                        display: "flex",
                                                        fontSize: 10,
                                                        padding: 4,
                                                      }}
                                                    >
                                                      {value}
                                                    </Text>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          ))}
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              backgroundColor: "#D9D9D9",
                                              width: "100%",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                width: 595.6,
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                borderRight:
                                                  "1px solid #7C7C7C",
                                              }}
                                            >
                                              <Text
                                                style={{
                                                  padding: 4,
                                                  fontSize: 10,
                                                  color: "#7C7C7C",
                                                }}
                                              ></Text>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  gap: 5,
                                                }}
                                              >
                                                {/* <Text
                                                  style={{
                                                    display: "flex",
                                                    fontSize: 10,
                                                    width: 65.5,
                                                  }}
                                                >
                                                  {v?.tariffTotal}
                                                </Text> */}
                                                <Text
                                                  style={{
                                                    display: "flex",
                                                    fontSize: 10,
                                                    width: 90,
                                                  }}
                                                ></Text>
                                                <Text
                                                  style={{
                                                    display: "flex",
                                                    fontSize: 10,
                                                    width: 90,
                                                  }}
                                                ></Text>
                                              </div>
                                            </div>
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                backgroundColor: "#D9D9D9",
                                                alignItems: "center",
                                                gap: 4,
                                              }}
                                            >
                                              {/* <Text
                                                style={{
                                                  fontSize: 9,
                                                  padding: 4,
                                                  color: "#7C7C7C",
                                                }}
                                              >
                                                {"Total\nTax./Imp."}
                                              </Text>
                                              <Text
                                                style={{
                                                  fontSize: 10,
                                                  padding: 4,
                                                }}
                                              >
                                                {v?.currency}$
                                                {formatCurrency(
                                                  v?.totals?.taxes
                                                    ?.totalInCurrency
                                                )}
                                              </Text> */}
                                              <Text
                                                style={{
                                                  fontSize: 9,
                                                  padding: 4,

                                                  color: "#7C7C7C",
                                                }}
                                              >
                                                {"Total\nmoeda/convertido"}
                                              </Text>
                                              <Text
                                                style={{
                                                  fontSize: 10,
                                                  padding: 4,
                                                }}
                                              >
                                                {v?.currency}$
                                                {formatCurrency(
                                                  v?.totals?.totals
                                                    ?.totalInCurrency
                                                )}{" "}
                                                / R$
                                                {formatCurrency(
                                                  v?.totals?.totals?.totalInBRL
                                                )}
                                              </Text>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </View>
                        </div>
                      );
                    }
                  )}
                  {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: 669.5,
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderRight: "1px solid #7C7C7C",
                      backgroundColor: "#F3F3F3",
                    }}
                  ></div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      backgroundColor: "#B3B3B3",
                      flex: 1,
                      justifyContent: "flex-end",
                      padding: 4,
                    }}
                  >
                    <Text
                      style={{
                        padding: 4,
                        fontSize: 10,
                        color: "#2F2F2F",
                      }}
                    >
                      Total moeda/convertido US$281.983,01 / R$1.406.915,05
                    </Text>
                  </div>
                </div> */}
                </>
              );
            });
          })}
        </div>
      </View>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "98%",
          marginLeft: 12,
          marginRight: 12,
          marginTop: 32,
          marginBottom: 16,
        }}
      >
        <Text style={{ ...styles.header2, fontWeight: "bold" }}>Totais</Text>
        <Text
          style={{
            ...styles.header2,
            fontSize: "14px",
            ...styles.grayText,
            marginTop: "10px",
          }}
        >
          Totais do orçamentos
        </Text>
      </div>

      {dividedBudgetTotals.map((budgetItem: any, budgetItemIndex: number) => {
        const budgetServices = getUniqueKeys(budgetItem).filter((i: any) => i);

        return (
          <View
            style={{
              flexDirection: "column",
              flex: 1,
              width: "98%",
              marginLeft: 12,
              marginRight: 12,
            }}
            wrap={false}
          >
            <div style={{ flexDirection: "row" }}>
              <div style={{ flex: 1, flexDirection: "row", padding: 4 }}></div>
              {budgetItem?.map((i: any, index: number) => {
                const indexes = i?.[0];
                return (
                  <div
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      backgroundColor: "black",
                      padding: 4,
                    }}
                  >
                    <Text style={{ color: "white", fontSize: 10 }}>
                      {i?.[0]?.name}
                    </Text>
                  </div>
                );
              })}
            </div>
            {budgetServices?.map((service) => {
              const totalsLabels: any = [
                { label: "Total tarifa" },
                { label: "Total Tax./Imp." },
              ];

              let currencies: any[] = [];

              budgetItem.forEach((b: any) => {
                b.forEach((i: any) => {
                  if (i?.currency && i?.currency !== "BRL") {
                    currencies.push(i?.currency);
                  }
                });
              });

              const uniqueCurrencies = getUniqueValues(currencies).map((c) => {
                return {
                  label: `Total (${c})`,
                  value: c,
                };
              });

              const allLabels = [
                // ...totalsLabels,
                // ...uniqueCurrencies,
                { label: "Total (R$)" },
              ];

              return (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      backgroundColor: "#B3B3B3",
                      padding: 4,
                    }}
                  >
                    <Text style={{ fontSize: 10, color: "white" }}>
                      {service}
                    </Text>
                  </div>
                  {allLabels.map((label: any) => (
                    <div
                      style={{
                        flexDirection: "row",
                        borderBottom: "1px solid #B3B3B3",
                      }}
                    >
                      <div
                        style={{ flex: 1, flexDirection: "row", padding: 4 }}
                      >
                        <Text style={{ fontSize: 10 }}>{label.label}</Text>
                      </div>
                      {budgetItem?.map((itemList: any) => {
                        const findItem = itemList?.find(
                          (i: any) => i.key === service
                        );

                        const foundCurrency =
                          findItem?.currency === label?.value
                            ? findItem?.totalCostInCurrency
                            : null;

                        const keyLabels: any = {
                          ["Total tarifa"]: "tariffTotal",
                          ["Total Tax./Imp."]: "totalTaxes",
                          ["Total (USD)"]: "totalCostInCurrency",
                          ["Total (R$)"]: "totalCostInBRL",
                        };

                        return (
                          <div
                            style={{
                              flex: 1,
                              flexDirection: "row",
                              padding: 4,
                              backgroundColor: "#F3F3F3",
                            }}
                          >
                            <Text style={{ fontSize: 10, color: "#373737" }}>
                              {formatCurrency(
                                findItem?.[keyLabels[label.label]] ||
                                  foundCurrency
                              ) || "-"}
                            </Text>
                          </div>
                        );
                      })}
                    </div>
                  ))}
                </>
              );
            })}
          </View>
        );
      })}
    </Page>
  );
}
