import { createOptionsFromEnum } from "../../utils/formatters";
import { NFMMOptions, ServiceData } from "./service-item";

export type Budget = {
  project: string;
  _id?: string;
  status?: StatusBudget;
  name?: string;
  budgetServices: ServiceData[];
  itemTypeList: ItemTypeListPerCalcOption;
  addedItemTypeList: AddedTypes[];
  supplierList: AddedSupplier[];
  budgetValuesTotal: VersionTotals
  paymentSolicitation: IPaymentSolicitationByType[];
  files: AttachedFiles[];
  negociationValuesTotal: VersionTotals[];
  closingValuesTotal: VersionTotals
  isApiUpdate?: boolean;
  negotiationVersion: number;
  versionHistory: versionStatus[];
  budgetIndex: number;
};
type versionStatus = {
  version: number;
  status: string;
};

export type VersionTotals = {
  byCalculationType: AllTotals;
  byItemType: ByItemType;
}
export type CalcOption = "A" | "B" | "C" | "D" | "E";

export type AttachedFiles = {
  calcOption: CalcOption;
  supplierId: string;
  type: BudgetItemType;
  files: File[];
};

type AddedSupplier = {
  supplierId: string;
  calcOption: CalcOption;
};

export type ItemTypeList = {
  ACCOMMODATIONS?: boolean;
  ROOMS?: boolean;
  AEB?: boolean;
  EQUIPMENTS?: boolean;
  TRANSLATIONS?: boolean;
  SUPPORT?: boolean;
  SUBSCRIPTION?: boolean;
  RSVP?: boolean;
  AIR?: boolean;
  TRANSFER?: boolean;
  COMMUNICATIONS?: boolean;
  SEVERAL?: boolean;
};
export type ItemTypeListPerCalcOption = {
  A: ItemTypeList;
  B: ItemTypeList;
  C: ItemTypeList;
  D: ItemTypeList;
  E: ItemTypeList;
  budgetTotals: any;
};
export type AddedTypes = {
  type: BudgetItemType;
  supplierId: string;
  calcOption: HeaderOptions;
};

export type TotalByCalcOption = {
  [key in NFMMOption]: TotalByType;
};

export interface AllTotals extends TotalByCalcOption {
  budgetTotals: SumsTotalByType;
}
// Nova tipagem criada pois a resposta da API está diferente da tipagem proposta
export interface ByItemType extends SumsTotalByType {
  SUM: Totals;
}

export type TotalByType = {
  [key in BudgetItemType]: TotalSplited[];
} & {
  SUM: TotalSplited[];
};
export type SumsTotalByType = {
  [key in BudgetItemType]: Totals;
} & {
  SUM: Totals;
};
export type NFMMCalcItem = {
  [key in NFMMOption]: TotalSplited;
};
export type StepTotals = {
  budgetValues: Totals;
  negociationValues: Totals[];
  closingValues: Totals;
};
export type TotalSplited = {
  supplierId?: string;
  calcOption?: CalcOption;
  taxes: Totals;
  totals: Totals;
};
export type Totals = {
  totalInBRL: number;
  totalInCurrency: number;
};
export type NFMMOption = CalcOption;
export type HeaderOptions = "A" | "B" | "C" | "D" | "E" | "budgetTotals";
export interface IExchangeAndDescriptionByType {
  _id: string;
  supplierId: string;
  type: BudgetItemType;
  negotiationVersion: number;
  exchangeData: ExchangeData;
  calcOption: HeaderOptions;
  descriptions: {
    composition: string;
    cancelPolicy: string;
    paymentMethod: string;
  };
}
export interface IPaymentSolicitationByType {
  _id: string;
  supplierId: string;
  type: BudgetItemType;
  calcOption: NFMMOption;
  exchangeData: ExchangeData;
  billing: string;
  comisionType: string;
  paymentMethod: PaymentMethods[];
  paymentMethodDescription: string;
  downPaymentExchangeData: ExchangeData;
}
export const initialPaymentSolicitation: IPaymentSolicitationByType = {
  _id: "new",
  supplierId: "",
  type: "" as BudgetItemType, // Default placeholder, replace as needed
  calcOption: "" as NFMMOption, // Default placeholder, replace as needed
  exchangeData: {
    date: "",
    currency: "",
    value: 0,
  },
  billing: "",
  comisionType: "",
  paymentMethod: [], // Starts as an empty array
  paymentMethodDescription: "",
  downPaymentExchangeData: {
    date: "",
    currency: "",
    value: 0,
  },
};

export enum PaymentMethods {
  CREDIT_CARD = "Cartão de Crédito",
  DEBIT_CARD = "Cartão de Débito",
  CASH = "Dinheiro",
  BANK_TRANSFER = "Transferência Bancária",
  PIX = "PIX",
  BOLETO = "Boleto",
  PAYPAL = "PayPal",
  CRYPTOCURRENCY = "Criptomoeda",
  VOUCHER = "Vale/ Voucher",
}
export interface ExchangeData {
  date: string;
  currency: string;
  value: number;
}

export type StatusBudget = keyof typeof BudgetStatusEnum;

export enum BudgetStatusEnum {
  OPEN = "Aberto",
  APROVED = "Aprovado",
  NEGOTIATE = "Negociação",
  APPROVED_NEGOTIATION = "Negociação Aprovada",
  DENIED = "Negado",
  AUDITED = "Auditado",
  ON_BUDGET = "Em orçamento",
  ON_APPROVAL = "Em aprovação",
  CLOSURE = "Fechamento",
  DELETED = "Apagado",
}

export enum BudgetStatus {
  OPEN = "OPEN",
  APPROVED = "APPROVED",
  NEGOTIATION = "NEGOTIATION",
  APPROVED_NEGOTIATION = "APPROVED_NEGOTIATION",
  DECLINED = "DECLINED",
  AUDITED = "AUDITED",
  ON_BUDGET = "ON_BUDGET",
  ON_APPROVAL = "ON_APPROVAL",
  CLOSURE = "CLOSURE",
}
export type BudgetItemType =
  | "ACCOMMODATIONS"
  | "ROOMS"
  | "AEB"
  | "EQUIPMENTS"
  | "TRANSLATIONS"
  | "SUPPORT"
  | "SUBSCRIPTION"
  | "RSVP"
  | "AIR"
  | "TRANSFER"
  | "COMMUNICATIONS"
  | "SEVERAL";

export enum BudgetItemTypeEnum {
  ACCOMMODATIONS = "Hospedagem",
  ROOMS = "Salas",
  AEB = "A & B e Catering",
  EQUIPMENTS = "Equipamentos",
  TRANSLATIONS = "Tradução simultânea",
  SUPPORT = "Equipe e suporte",
  SUBSCRIPTION = "Inscrição",
  RSVP = "RSVP",
  AIR = "Aéreo",
  TRANSFER = "Transfer",
  COMMUNICATIONS = "Comunicação",
  SEVERAL = "Outros",
}

export enum EventLocation {
  RECEPTION = "RECEPÇÃO",
  FOYER = "FOYER",
  FOYER_2 = "FOYER 2",
  AUDITORIUM_1 = "PLENÁRIA 1",
  AUDITORIUM_2 = "PLENÁRIA 2",
  COCKTAIL = "COQUETEL",
  LUNCH = "ALMOÇO",
  OTHERS = "OUTROS",
  AUDITORIUM = "PLENÁRIA",
  TECHNOLOGICAL_ACTIVATION = "ATIVAÇÃO TECNOLÓGICA",
  GASTRONOMIC_ACTIVATION = "ATIVAÇÃO GASTRONOMICA",
  STAFF_ROOM = "SALA DE STAFF",
}

export enum BudgetItemTotalRelation {
  hotels = "Hospedagem",
  rooms = "Salas",
  foodAndDrinks = "A & B e catering",
  equipment = "Equipamentos",
  simultaneousTranslation = "Tradução simultânea",
  suportTeams = "Equipe e suporte",
  subscription = "Inscrição",
  rsvp = "RSVP",
  air = "Aéreo",
  transfer = "Transfer",
  communications = "Comunicação",
  several = "Outros",
}
export const itemOptions = createOptionsFromEnum(BudgetItemTypeEnum);
export enum BudgetTotalsCosts {
  PREVISION = "Custo total previsão",
  SERVICE = "Taxa de Serviço",
  ISS = "ISS",
  TARIFF = "Tarifa",
  LOCAL_TAX = "Imposto Local",
  TOURISM_TAX = "Taxa de Turismo",
  FEE = "Taxa MM (Fee)",
  NFMM = "Tipo de NF MM",
  EXCHANGE = "Cambio",
  NFMM_TAX = "Impostos NF MM",
  EXTRAS_PREVISION = "Previsão de extras",
  TOTAL_COST_PREVISION = "Total previsão de custos",
}
export enum BudgetCostsKeysToTotals {
  PREVISION = "costForecastSubtotal",
  SERVICE = "totalServiceTax",
  ISS = "ISS",
  TARIFF = "totalTariff",
  IOF = "totalTaxIof",
  MM_TAX = "Taxa MM eventos(fees)",
  EXCHANGE = "totalExchange",
  NFMM_TAX = "totalNfMm",
  EXTRAS_PREVISION = "extras",
  TOTAL_COST_PREVISION = "total",
}

export enum BudgetSummaryActionOptions {
  DUPLICATE = "Duplicar",
  DELETE = "Excluir",
  APROVE = "Aprovar",
  DENY = "Reprovar",
  NEGOTIATE = "Enviar para negociação",
  NEW_VERSION = "Criar nova versão",
  RENAME = "Renomear",
  // IMPORT = 'Importar de logística',
  // ORDER_ASC = 'Ordenar valores do maior para o menor',
  // ORDER_DESC = 'Ordenar valores do menor para o maior'
}
