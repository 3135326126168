import { Container } from "./styles";
import { Box, Card } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";

import { LogisticAccordion } from "../novos/LogisticAccordion";
import LogisticTypesTab from "../novos/LogisticTypesTab";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { SupplierLogistic } from "../../services/supplier-logistic.service";
import { useProject } from "../../contexts/project.context";
export default function Logistica() {
  const queryClient = useQueryClient();
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get("projectId") || "";

  const {
    isLoading,
    data: mainLogistics,
    error,
  } = useQuery({
    queryKey: [`logistics`],
    queryFn: () => SupplierLogistic.getLogistics(projectId),
  });

  const { mutate: updateLogistics, isLoading: isLoadingUpdate } = useMutation(
    (state: any) => SupplierLogistic.update(state._id, state),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
      onError: (error) => {},
    }
  );
  const { mutate: createLogistics, isLoading: isLoadingCreate } = useMutation(
    (state: any) => SupplierLogistic.create(state),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
      onError: (error) => {},
    }
  );
  useEffect(() => {
    if (mainLogistics?.data === "")
      createLogistics({
        project: projectId,
        briefing_id: "",
        accommodations: null,
        rooms: null,
        food_and_drinks: null,
        equipments: null,
        simultaneous_translation: null,
        support_teams: null,
        subscription: null,
        rsvp: null,
        air: null,
        transfer: null,
        comunication: null,
        several: null,
      });
  }, [mainLogistics]);

  function handleActiveTypesChange(newList: any): void {
    updateLogistics(newList);
  }

  return (
    <Container>
      <Card
        sx={{
          display: "flex",
          mt: 2,
          mx: 5,
          boxShadow: "0px 4px 18px 0px #4B465C1A",
          maxWidth: 1480,
          width: "100%",
          padding: 1,
        }}
      >
        <LogisticTypesTab
          logisticTypes={mainLogistics?.data || {}}
          onChange={handleActiveTypesChange}
          isDisabled={isLoadingUpdate}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            padding: 2,
            gap: 1.5,
          }}
        >
          {mainLogistics?.data.accommodations && (
            <LogisticAccordion
              logisticsId={mainLogistics.data._id}
              accordionType={"ACCOMMODATIONS"}
              title={"Hospedagem"}
            />
          )}
          {mainLogistics?.data.rooms && (
            <LogisticAccordion
              logisticsId={mainLogistics.data._id}
              accordionType={"ROOMS"}
              title={"Salas"}
            />
          )}
          {mainLogistics?.data.food_and_drinks && (
            <LogisticAccordion
              logisticsId={mainLogistics.data._id}
              accordionType={"AEB"}
              title={"A & B e Catering"}
            />
          )}
          {mainLogistics?.data.equipments && (
            <LogisticAccordion
              logisticsId={mainLogistics.data._id}
              accordionType={"EQUIPMENTS"}
              title={"Equipamentos"}
            />
          )}
          {mainLogistics?.data.simultaneous_translation && (
            <LogisticAccordion
              logisticsId={mainLogistics.data._id}
              accordionType={"TRANSLATIONS"}
              title={"Tradução simultânea"}
            />
          )}
          {mainLogistics?.data.support_teams && (
            <LogisticAccordion
              logisticsId={mainLogistics.data._id}
              accordionType={"SUPPORT"}
              title={"Equipe e suporte"}
            />
          )}
          {mainLogistics?.data.subscription && (
            <LogisticAccordion
              logisticsId={mainLogistics.data._id}
              accordionType={"SUBSCRIPTION"}
              title={"Inscrição"}
            />
          )}
          {mainLogistics?.data.rsvp && (
            <LogisticAccordion
              logisticsId={mainLogistics.data._id}
              accordionType={"RSVP"}
              title={"RSVP"}
            />
          )}
          {mainLogistics?.data.air && (
            <LogisticAccordion
              logisticsId={mainLogistics.data._id}
              accordionType={"AIR"}
              title={"Aéreo"}
            />
          )}
          {mainLogistics?.data.transfer && (
            <LogisticAccordion
              logisticsId={mainLogistics.data._id}
              accordionType={"TRANSFER"}
              title={"Transfer"}
            />
          )}
          {mainLogistics?.data.comunication && (
            <LogisticAccordion
              logisticsId={mainLogistics.data._id}
              accordionType={"COMMUNICATIONS"}
              title={"Comunicação"}
            />
          )}
          {mainLogistics?.data.several && (
            <LogisticAccordion
              logisticsId={mainLogistics.data._id}
              accordionType={"SEVERAL"}
              title={"Outros"}
            />
          )}
        </Box>
      </Card>
    </Container>
  );
}
