import { Budget } from "../types/budget/budget";
import { ServiceData } from "../types/budget/service-item";
import { api } from "./apiClient";

interface UploadFileParams {
  id: string;
  type: string;
  supplierId: string;
  calcOption: string;
  file: FormData;
}

interface UploadFileResponse {
  success: boolean;
  file: {
    name: string;
    path: string;
    mimeType: string;
    size: number;
    uploadedAt: string;
  };
}

export class BudgetService {
  static async getBudgetList(projectId: string): Promise<any> {
    return api
      .get("/budget/get?", {
        params: {
          project: projectId,
        },
      })
      .then((response) => response.data);
  }

  static async uploadFile(
    id: string,
    type: string,
    supplierId: string,
    calcOption: string,
    file: FormData
  ): Promise<{ data: UploadFileResponse }> {
    return api.post(
      `budget/upload/${id}/${type}/${supplierId}/${calcOption}`,
      file,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }
  static async deleteFile(
    id: string,
    type: string,
    supplierId: string,
    calcOption: string,
    fileHash: string
  ): Promise<any> {
    return api.delete(
      `budget/delete-file/${id}/${type}/${supplierId}/${calcOption}/${fileHash}`,
    );
  }

  static async changeFileName(
    id: string,
    type: string,
    supplierId: string,
    calcOption: string,
    fileHash: string,
    newName: string
  ): Promise<any> {
    return api.post(
      `budget/change-name/${id}/${type}/${supplierId}/${calcOption}/${fileHash}/${newName}`,
    );
  }

  static async downloadFile(
    id: string,
    hash: string,
   
  ) {
    try {
      const res = await api.get(
        `budget/download/${id}/${hash}`
      );
      return res.data
    } catch (error) {
      console.log(error);
    }
  }

  static async getServicesFromBudget(budgetId: string): Promise<any> {
    return api
      .get("/budget-items/get?", {
        params: {
          budgetId: budgetId,
        },
      })
      .then((response) => {
        console.log(response);
        return response.data;
      });
  }

  static async update(id: string, budgetData: any): Promise<any> {
    return api
      .put(`/budget/update/${id}`, { ...budgetData })
      .then((response) => response.data);
  }
  static async create(data: Budget): Promise<any> {
    return api.post("/budget/create", data).then((response) => response.data);
  }
  static async createService(data: ServiceData): Promise<any> {
    return api
      .post("/budget-items/create", data)
      .then((response) => response.data);
  }
  static async updateService(data: ServiceData): Promise<any> {
    return api
      .put(`/budget-items/update/${data._id}`, data)
      .then((response) => response.data);
  }
  static async deleteService(id: string): Promise<any> {
    return api
      .delete(`budget-items/delete/${id}`)
      .then((response) => response.data);
  }
  static async calculateService(data: Budget): Promise<any> {
    return api
      .post("/budget-items/calculate-item", data)
      .then((response) => response.data);
  }

  static async calculateBudgetTotals(data: any): Promise<any> {
    return api
      .post("/budget-total/calculate-total", data)
      .then((response) => response.data);
  }
  static async exportXLX({
    ids,
    exportType,
  }: {
    ids: string[];
    exportType: string;
  }): Promise<any> {
    return api
      .get(`/export-budget/generate-cost-estimates`, {
        responseType: "arraybuffer",
        params: { ids, exportType },
      })
      .then((response) => {
        return response?.data;
      });
  }

  static async exportNegotiationXLX(
    budgets: { budgetId: string; negotiationVersions: number[] }[]
  ): Promise<any> {
    return api
      .post(
        `/export-budget-cost-estimates-negotiation`,
        {
          budgets,
        },
        {
          responseType: "arraybuffer",
        }
      )
      .then((response) => {
        return response?.data;
      });
  }
}
