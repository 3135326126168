import { Box, Button } from "@mui/material";
import SupplierAutoComplete from "../SupplierAutoComplete";
import GenericServiceType from "../GenericServiceType";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { DefaultChildren } from "./custom-childrens/";
import { StyledH2After, StyledH2Before } from "./styles";
import { FaSave, FaTrashAlt } from "react-icons/fa";
import * as serviceTypes from "../../../utils/serviceTypesFromBudget";
import { LabeledInput } from "../../UI/LabeledInput";
import { AuthContext } from "../../../contexts/auth";

type PropTypes = {
  title: string;
  type:
    | "ACCOMMODATIONS"
    | "ROOMS"
    | "AEB"
    | "EQUIPMENTS"
    | "COMMUNICATIONS"
    | "TRANSLATIONS"
    | "SUPPORT"
    | "SUBSCRIPTION"
    | "RSVP"
    | "AIR"
    | "TRANSFER"
    | "SEVERAL";
  data: any;
  handleDeleteService(id: string): void;
  handleSaveService(data: any): void;
  serviceIsOpen: boolean;
};

const LogisticServiceItem: React.FC<PropTypes> = ({
  title,
  type,
  data,
  handleDeleteService,
  handleSaveService,
  serviceIsOpen,
}: PropTypes) => {
  const { actionOptions } = useContext(AuthContext);
  const [state, setState] = useState<any>({
    ...data,
  });

  function handleStateChange(value: string, field: string): void {
    setState({
      ...state,
      [`${field}`]:
        ["daily", "quantity"].includes(field) && parseInt(value) > 0
          ? parseInt(value)
          : value,
    });
  }
  function chooseChildren() {
    switch (type) {
      case "ACCOMMODATIONS":
      default:
        return (
          <DefaultChildren data={state} onFieldChange={handleStateChange} />
        );
    }
  }

  const isDisabled =
    !actionOptions.createProjetos && !actionOptions.updateProjetos;
  const disabledColor = "#ddd";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "94%",
        alignItems: "flex-start",
        border: "1px solid #E0E0E0",
        padding: 2,
        borderRadius: 1,
      }}
    >
      <span style={{ marginBottom: 16 }}>{title}</span>
      <SupplierAutoComplete
        onSelectSupplier={handleStateChange}
        provider_id={state.provider_id}
        title=""
      />
      <div style={{ height: 8 }} />
      {serviceIsOpen ? (
        <Box width={"90%"}>
          <LabeledInput
            label="Tipo de serviço"
            multiline
            value={state.service_type}
            onChange={(e) =>
              setState({ ...state, service_type: e.target.value })
            }
          />
        </Box>
      ) : (
        <GenericServiceType
          onSelectServiceType={handleStateChange}
          previousSelectedServiceType={state.service_type || ""}
          serviceOptions={serviceTypes[type]}
        />
      )}

      {chooseChildren()}

      <Box width={"100%"} display={"flex"} flexDirection={"column"} my={1}>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
          gap={1}
        >
          <Button
            onClick={() => {
              handleSaveService(state);
            }}
            disabled={isDisabled}
            style={{ border: 0 }}
          >
            <span
              style={{
                background: "transparent",
                display: "inline-flex",
                alignItems: "center",
                zIndex: 10,
              }}
            >
              <FaSave size={14} /> &nbsp; Salvar
            </span>
          </Button>
          {/* <Button
            onClick={() => { handleDeleteService(state._id) }}
            disabled={isDisabled}
            style={{ border: 0 }} variant="outlined"> <span style={{ background: 'transparent', color: isDisabled ? disabledColor : '#FF6262', display: 'inline-flex', alignItems: 'center', zIndex: 10 }}>  <FaTrashAlt size={14} color={isDisabled ? disabledColor : '#FF8484'} /> &nbsp; Apagar </span></Button> */}
        </Box>
      </Box>
    </Box>
  );
};

export { LogisticServiceItem };
